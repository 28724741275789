// @flow
import Window from '../Window';

const isDev = Window.isDev();

export const GDevelopGamePreviews = {
  baseUrl: `https://game-previews.engine.spacexpanse.org/`,
};

export const GDevelopGamesPlatform = {
  getInstantBuildUrl: (buildId: string) =>
    isDev
      ? `https://games.engine.spacexpanse.org/instant-builds/${buildId}?dev=true`
      : `https://games.engine.spacexpanse.org/instant-builds/${buildId}`,
  getGameUrl: (gameId: string) =>
    isDev
      ? `https://games.engine.spacexpanse.org/games/${gameId}?dev=true`
      : `https://games.engine.spacexpanse.org/games/${gameId}`,
  getGameUrlWithSlug: (userSlug: string, gameSlug: string) =>
    isDev
      ? `https://games.engine.spacexpanse.org/${userSlug.toLowerCase()}/${gameSlug.toLowerCase()}?dev=true`
      : `https://games.engine.spacexpanse.org/${userSlug.toLowerCase()}/${gameSlug.toLowerCase()}`,
  getUserPublicProfileUrl: (userId: string, username: ?string) =>
    username
      ? `https://games.engine.spacexpanse.org/${username}${isDev ? '?dev=true' : ''}`
      : `https://games.engine.spacexpanse.org/user/${userId}${isDev ? '?dev=true' : ''}`,
};

export const GDevelopFirebaseConfig = {
  apiKey: 'SpeX-AIzaSyAnX9QMacrIl3yo4zkVFEVhDppGVDDewBc',
  authDomain: 'gdevelop-services.firebaseapp.com',
  databaseURL: 'https://gdevelop-services.firebaseio.com',
  projectId: 'gdevelop-services',
  storageBucket: 'gdevelop-services.appspot.com',
  messagingSenderId: '44882707384',
};

export const GDevelopBuildApi = {
  baseUrl: isDev
    ? 'https://api-dev.engine.spacexpanse.org/build'
    : 'https://api.engine.spacexpanse.org/build',
};

export const GDevelopUsageApi = {
  baseUrl: isDev
    ? 'https://api-dev.engine.spacexpanse.org/usage'
    : 'https://api.engine.spacexpanse.org/usage',
};

export const GDevelopReleaseApi = {
  baseUrl: isDev
    ? 'https://api-dev.engine.spacexpanse.org/release'
    : 'https://api.engine.spacexpanse.org/release',
};

export const GDevelopAssetApi = {
  baseUrl: isDev
    ? 'https://api-dev.engine.spacexpanse.org/asset'
    : 'https://api.engine.spacexpanse.org/asset',
};

export const GDevelopAnalyticsApi = {
  baseUrl: isDev
    ? 'https://api-dev.engine.spacexpanse.org/analytics'
    : 'https://api.engine.spacexpanse.org/analytics',
};

export const GDevelopGameApi = {
  baseUrl: isDev
    ? 'https://api-dev.engine.spacexpanse.org/game'
    : 'https://api.engine.spacexpanse.org/game',
};

export const GDevelopUserApi = {
  baseUrl: isDev
    ? 'https://api-dev.engine.spacexpanse.org/user'
    : 'https://api.engine.spacexpanse.org/user',
};

export const GDevelopPlayApi = {
  baseUrl: isDev
    ? 'https://api-dev.engine.spacexpanse.org/play'
    : 'https://api.engine.spacexpanse.org/play',
};

export const GDevelopShopApi = {
  baseUrl: isDev
    ? 'https://api-dev.engine.spacexpanse.org/shop'
    : 'https://api.engine.spacexpanse.org/shop',
};

export const GDevelopProjectApi = {
  baseUrl: isDev
    ? 'https://api-dev.engine.spacexpanse.org/project'
    : 'https://api.engine.spacexpanse.org/project',
};

export const GDevelopGenerationApi = {
  baseUrl: isDev
    ? 'https://api-dev.engine.spacexpanse.org/generation'
    : 'https://api.engine.spacexpanse.org/generation',
};

export const GDevelopProjectResourcesStorage = {
  baseUrl: isDev
    ? 'https://project-resources-dev.engine.spacexpanse.org'
    : 'https://project-resources.engine.spacexpanse.org',
};

export const GDevelopPrivateAssetsStorage = {
  baseUrl: isDev
    ? 'https://private-assets-dev.engine.spacexpanse.org'
    : 'https://private-assets.engine.spacexpanse.org',
};

export const GDevelopPublicAssetResourcesStorageBaseUrl =
  'https://asset-resources.engine.spacexpanse.org';
export const GDevelopPublicAssetResourcesStorageStagingBaseUrl =
  'https://asset-resources.engine.spacexpanse.org/staging';
