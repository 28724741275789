// This file is generated by create-new-theme.js script
import DefaultDarkTheme from './DefaultDarkTheme';
import DefaultLightTheme from './DefaultLightTheme';
import BlueDarkTheme from './BlueDarkTheme';
import NordTheme from './NordTheme';
import SolarizedDarkTheme from './SolarizedDarkTheme';
import OneDarkTheme from './OneDarkTheme';
import RosePineTheme from './RosePineTheme';

/*eslint no-useless-computed-key: "off"*/
export const themes = {
  ['SpeXEngine default Dark']: DefaultDarkTheme,
  ['SpeXEngine default Light']: DefaultLightTheme,
  ['Blue Dark']: BlueDarkTheme,
  ['Nord']: NordTheme,
  ['Solarized Dark']: SolarizedDarkTheme,
  ['One Dark']: OneDarkTheme,
  ['Rosé Pine']: RosePineTheme,
};
