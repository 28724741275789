// This file is generated by compile-translations.js script
// prettier-ignore
module.exports = [
  {
    "languageCode": "af_ZA",
    "languageName": "Afrikaans",
    "languageNativeName": "Afrikaans",
    "translationRatio": 0.05233265720081137
  },
  {
    "languageCode": "ar_SA",
    "languageName": "Arabic",
    "languageNativeName": "اللغة العربية",
    "translationRatio": 0.9610547667342799
  },
  {
    "languageCode": "az_AZ",
    "languageName": "Azerbaijani",
    "languageNativeName": "azərbaycan dili",
    "translationRatio": 0.0359702501690331
  },
  {
    "languageCode": "bg_BG",
    "languageName": "Bulgarian",
    "languageNativeName": "български език",
    "translationRatio": 0.10033806626098718
  },
  {
    "languageCode": "bn_BD",
    "languageName": "Bengali",
    "languageNativeName": "বাংলা",
    "translationRatio": 0.055983772819472644
  },
  {
    "languageCode": "br_FR",
    "languageName": "Breton",
    "languageNativeName": "brezhoneg",
    "translationRatio": 0.019743069641649735
  },
  {
    "languageCode": "ca_ES",
    "languageName": "Catalan",
    "languageNativeName": "Català",
    "translationRatio": 0.27518593644354294
  },
  {
    "languageCode": "cs_CZ",
    "languageName": "Czech",
    "languageNativeName": "čeština",
    "translationRatio": 0.24705882352941178
  },
  {
    "languageCode": "da_DK",
    "languageName": "Danish",
    "languageNativeName": "dansk",
    "translationRatio": 0.060175794455713305
  },
  {
    "languageCode": "de_DE",
    "languageName": "German",
    "languageNativeName": "Deutsch",
    "translationRatio": 0.6923597025016903
  },
  {
    "languageCode": "el_GR",
    "languageName": "Greek",
    "languageNativeName": "Ελληνικά",
    "translationRatio": 0.23624070317782286
  },
  {
    "languageCode": "en",
    "languageName": "English",
    "languageNativeName": "English",
    "translationRatio": 0.056392429509463105
  },
  {
    "languageCode": "eo_UY",
    "languageName": "Esperanto",
    "languageNativeName": "Esperanto",
    "translationRatio": 0.27423935091277896
  },
  {
    "languageCode": "es_ES",
    "languageName": "Spanish",
    "languageNativeName": "Español",
    "translationRatio": 0.9820148749154834
  },
  {
    "languageCode": "fa_IR",
    "languageName": "Persian",
    "languageNativeName": "فارسی",
    "translationRatio": 0.19202163624070323
  },
  {
    "languageCode": "fi_FI",
    "languageName": "Finnish",
    "languageNativeName": "suomi",
    "translationRatio": 0.2200135226504395
  },
  {
    "languageCode": "fil_PH",
    "languageName": "Filipino",
    "languageNativeName": "Mga Filipino",
    "translationRatio": 0.303448275862069
  },
  {
    "languageCode": "fr_FR",
    "languageName": "French",
    "languageNativeName": "Français",
    "translationRatio": 0.98052738336714
  },
  {
    "languageCode": "ha_HG",
    "languageName": "Hausa",
    "languageNativeName": "هَوُسَ",
    "translationRatio": 0.0240703177822853
  },
  {
    "languageCode": "he_IL",
    "languageName": "Hebrew",
    "languageNativeName": "עברית",
    "translationRatio": 0.20229885057471264
  },
  {
    "languageCode": "hi_IN",
    "languageName": "Hindi",
    "languageNativeName": "हिन्दी",
    "translationRatio": 0.184448951994591
  },
  {
    "languageCode": "hu_HU",
    "languageName": "Hungarian",
    "languageNativeName": "magyar",
    "translationRatio": 0.16037863421230558
  },
  {
    "languageCode": "id_ID",
    "languageName": "Indonesian",
    "languageNativeName": "Bahasa Indonesia",
    "translationRatio": 0.5023664638269101
  },
  {
    "languageCode": "ig_NG",
    "languageName": "Igbo",
    "languageNativeName": "Asụsụ Igbo",
    "translationRatio": 0.019743069641649735
  },
  {
    "languageCode": "it_IT",
    "languageName": "Italian",
    "languageNativeName": "Italiano",
    "translationRatio": 0.8853279242731575
  },
  {
    "languageCode": "ja_JP",
    "languageName": "Japanese",
    "languageNativeName": "日本語",
    "translationRatio": 0.9786342123056119
  },
  {
    "languageCode": "ka_GE",
    "languageName": "Georgian",
    "languageNativeName": "ქართული",
    "translationRatio": 0.0494929006085193
  },
  {
    "languageCode": "km_KH",
    "languageName": "Khmer",
    "languageNativeName": "ខេមរភាសា",
    "translationRatio": 0.025828262339418484
  },
  {
    "languageCode": "ko_KR",
    "languageName": "Korean",
    "languageNativeName": "한국어",
    "translationRatio": 0.519134550371873
  },
  {
    "languageCode": "lt_LT",
    "languageName": "Lithuanian",
    "languageNativeName": "lietuvių kalba",
    "translationRatio": 0.04448951994590944
  },
  {
    "languageCode": "lv_LV",
    "languageName": "Latvian",
    "languageNativeName": "latviešu valoda",
    "translationRatio": 0.01987829614604464
  },
  {
    "languageCode": "mr_IN",
    "languageName": "Marathi",
    "languageNativeName": "मराठी",
    "translationRatio": 0.03421230561189992
  },
  {
    "languageCode": "ms_MY",
    "languageName": "Malay",
    "languageNativeName": "Bahasa Malaysia",
    "translationRatio": 0.05395537525354965
  },
  {
    "languageCode": "my_MM",
    "languageName": "Burmese",
    "languageNativeName": "ဗမာစာ",
    "translationRatio": 0.020148749154834333
  },
  {
    "languageCode": "nl_NL",
    "languageName": "Dutch",
    "languageNativeName": "Nederlands",
    "translationRatio": 0.49519945909398244
  },
  {
    "languageCode": "no_NO",
    "languageName": "Norwegian",
    "languageNativeName": "Norsk",
    "translationRatio": 0.28789722785665994
  },
  {
    "languageCode": "pl_PL",
    "languageName": "Polish",
    "languageNativeName": "język polski",
    "translationRatio": 0.7467207572684247
  },
  {
    "languageCode": "pseudo_LOCALE",
    "languageName": "for development only",
    "languageNativeName": "Pseudolocalization",
    "translationRatio": 0.9972962533796833
  },
  {
    "languageCode": "pt_BR",
    "languageName": "Brazilian Portuguese",
    "languageNativeName": "Português brasileiro",
    "translationRatio": 0.8969574036511156
  },
  {
    "languageCode": "pt_PT",
    "languageName": "Portuguese",
    "languageNativeName": "Português",
    "translationRatio": 0.34996619337390134
  },
  {
    "languageCode": "ro_RO",
    "languageName": "Romanian",
    "languageNativeName": "Română",
    "translationRatio": 0.11102096010818119
  },
  {
    "languageCode": "ru_RU",
    "languageName": "Russian",
    "languageNativeName": "Русский",
    "translationRatio": 0.9510480054090602
  },
  {
    "languageCode": "si_LK",
    "languageName": "Sinhala",
    "languageNativeName": "සිංහල",
    "translationRatio": 0.7517241379310344
  },
  {
    "languageCode": "sk_SK",
    "languageName": "Slovak",
    "languageNativeName": "slovenčina",
    "translationRatio": 0.19959432048681547
  },
  {
    "languageCode": "sl_SI",
    "languageName": "Slovene",
    "languageNativeName": "slovenski jezik",
    "translationRatio": 0.7856659905341447
  },
  {
    "languageCode": "sq_AL",
    "languageName": "Albanian",
    "languageNativeName": "Shqip",
    "translationRatio": 0.17633536173089925
  },
  {
    "languageCode": "sr_CS",
    "languageName": "Serbian (Latin)",
    "languageNativeName": "srpski",
    "translationRatio": 0.1706558485463151
  },
  {
    "languageCode": "sr_SP",
    "languageName": "Serbian",
    "languageNativeName": "српски језик",
    "translationRatio": 0.34604462474645026
  },
  {
    "languageCode": "sv_SE",
    "languageName": "Swedish",
    "languageNativeName": "svenska",
    "translationRatio": 0.20081135902636915
  },
  {
    "languageCode": "sw_KE",
    "languageName": "Swahili",
    "languageNativeName": "Kiswahili",
    "translationRatio": 0.022177146720757324
  },
  {
    "languageCode": "th_TH",
    "languageName": "Thai",
    "languageNativeName": "ไทย",
    "translationRatio": 0.28451656524678837
  },
  {
    "languageCode": "tr_TR",
    "languageName": "Turkish",
    "languageNativeName": "Türkçe",
    "translationRatio": 0.8407031778228533
  },
  {
    "languageCode": "uk_UA",
    "languageName": "Ukrainian",
    "languageNativeName": "Українська",
    "translationRatio": 0.9868830290736984
  },
  {
    "languageCode": "ur_PK",
    "languageName": "Urdu",
    "languageNativeName": "اردو",
    "translationRatio": 0.025693035835023692
  },
  {
    "languageCode": "uz_UZ",
    "languageName": "Uzbek",
    "languageNativeName": "Ўзбек",
    "translationRatio": 0.02515212981744419
  },
  {
    "languageCode": "vi_VN",
    "languageName": "Vietnamese",
    "languageNativeName": "Tiếng Việt",
    "translationRatio": 0.2897903989181879
  },
  {
    "languageCode": "yo_NG",
    "languageName": "Yoruba",
    "languageNativeName": "Yorùbá",
    "translationRatio": 0.3260311020960108
  },
  {
    "languageCode": "zh_CN",
    "languageName": "Chinese Simplified",
    "languageNativeName": "简化字",
    "translationRatio": 0.9924273157538878
  },
  {
    "languageCode": "zh_TW",
    "languageName": "Chinese Traditional",
    "languageNativeName": "正體字",
    "translationRatio": 0.23948613928329954
  }
];
